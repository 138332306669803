@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.container-intance-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;


}

.container-cofigure-instance {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

}

.container-cofigure-instance img {
  height: 200px;
}

.container-validation-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-family: 'Inria Sans';
  padding: 5px 10px;
}

.container-validation-form span {
  font-size: 18pt;

}

.input-phone {
  width: 100%;


}

.validation-number-confirm {
  width: 100%;

}

.btn-generateQrCode {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #40B3A2;
  min-width: 200px;
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  padding: 16px 20px;
  margin-top: 0.5rem;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.btn-generateQrCode-generateQrCode:hover {
  opacity: .95;
}

.btn-generateQrCode .animation {
  border-radius: 100%;
  animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
  }
}

.formValidationContainer {
  width: 50%;
  height: 100%;

}

.perfil_instance {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Inria Sans";

}

.perfil_instance p {
  color: #1E1E1E;
}

#divisor {
  width: 1px;
  height: 100%;
  background-color: rgba(74, 72, 72, 0.5);
}


.editing-modal-qrcode {
  padding: 10px;
}

#modal-body-qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* Adiciona espaçamento entre os elementos */

}

.infomations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  width: 100%;
}


/* .infomations button {
  margin-top: 20px;
} */

.info-texts {
  display: flex;
  flex-direction: column;
  /* Organiza os itens verticalmente */
  justify-content: center;
  /* Centraliza os itens verticalmente */
  align-items: center;
  /* Centraliza os itens horizontalmente */
  text-align: center;
  /* Garante que o texto dentro do span fique centralizado */
  gap: 10px;
  /* Adiciona espaçamento entre os itens */
}

.info-pass {
  display: flex;
  align-items: center;
  /* Centraliza o ícone e o texto verticalmente */
  justify-content: center;
  /* Centraliza o ícone e o texto horizontalmente */
}

.info-pass i {
  margin-right: 10px;
  /* Adiciona espaçamento entre o ícone e o texto */
}

.info-pass span {
  font-size: 14px;
  /* Ajuste do tamanho do texto */
  font-weight: 400;
  /* Ajuste do peso da fonte */
}


.container-header-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: max-content;
}


.container-pensonalization-simples {
  font-family: "Inria Sans" !important;
  margin-top: 1rem;

  width: 100%;


}

.form-later-fluxos {
  width: 50%;
}

#robo-fresco {
  height: 150px;
}

.container-fluxosSimples {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imagen-container {
  display: flex;
  align-items: center;
}

.button {
  position: relative;
  overflow: hidden;
  height: 2.3rem;
  width: 100%;
  padding: 0 2rem;
  border-radius: 0.5rem;
  background: #3d3a4e;
  background-size: 400%;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14pt;
  transition: background-color 0.3s ease;
}

.button:disabled {
  background: #555;
  cursor: not-allowed;
}

.button:hover::before {
  transform: scaleX(1);
}

.button-content {
  position: relative;
  z-index: 1;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(82.3deg,
      rgba(150, 93, 233, 1) 10.8%,
      rgba(99, 88, 238, 1) 94.3%);
  transition: all 0.475s;
}

.result {
  margin-top: 1rem;
  text-align: center;

  .qr-code {
    margin-top: 0.5rem;
    max-width: 200px;
    max-height: 200px;
  }
}

.error {
  margin-top: 1rem;
  color: red;
  font-weight: bold;
}

/* botao de sincronizar  */
.btn-sinc {
  display: flex;
  justify-content: center;
  /* Centraliza o botão horizontalmente */
  align-items: center;
  /* Centraliza o botão verticalmente */
  margin-top: 1rem;
  /* Adiciona margem no topo */
}

.btn-synchronize {
  padding: 1.3rem 3rem;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fffe;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-top: 0rem;
}

.btn-synchronize:hover {
  background-color: #23c483;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.btn-synchronize:active {
  transform: translateY(-1px);
}

.btn-synchronize:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  box-shadow: none;
}

.phone-error-message {
  color: red;
  font-size: 14px;
  margin-top: 0.5rem;
  font-weight: bold;
}