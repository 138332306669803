.section-features {
    background-color: var(--neutral-color-light);
    padding: 60px 20px; 
    width:  100%;
   text-align: center;
   
    
}

.features-wrapper {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    gap: 20px;
    padding: 0 12px;
}

.features-card {
    background-color: var(--light-primary-color);
    padding: 20px;
    border-radius: 20px;
    margin: 0 15px;
    transition: 1s linear;
}

.features-title {
    color: var(--font-color-dark);
    font-size: 48px;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    margin: 0;
}

.features-subtitle {
    color: var(--font-color-dark);
    font-size: 22px;
    font-weight: var(--font-weight-bold);
    margin-bottom: 10px;
    transition: 1s linear;
}

.features-text {
    font-size: 18px;
    color: var(--font-color-medium);
    line-height: 1.4;
    transition: 1s linear;
}

.features-card:hover {
    background-color: var(--primary-color);
    transition: 1s linear;
}

.features-card:hover .features-subtitle,
.features-card:hover .features-text {
    color: var(--neutral-color-light);
    transition: 1s linear;
}