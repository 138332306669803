.section-target {
    background-color: var(--dark-primary-color);
    padding: 163px 19px;
    text-align: center;
    margin-top: 1rem;
    width: 100%;
}

.target-container {
    display: grid;
    grid-template-columns: 7fr 5fr;
    background-color: var(--dark-primary-color);
    color: var(--font-color-light);
    position: relative;
    /* Necessário para sobrepor as imagens */
}

.zoom-in {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    /* Começa invisível */
    transform: scale(0.5);
    /* Começa menor */
    transition: opacity 0.5s ease-in, transform 0.5s ease-in;
    /* Define as transições */
    max-height: 300px;
    /* Mantém a mesma altura para ambas as imagens */
    width: auto;
    /* Proporção automática */
}

.zoom-in-active {
    opacity: 1;
    /* Fica visível */
    transform: scale(1);
    /* Escala para o tamanho normal */
}

.image-wrapper {
    grid-column: 2 / 3;
    /* Garante que as imagens fiquem à direita */
    position: relative;
    /* Necessário para sobrepor as imagens */
    padding-left: 20px;
    /* Adiciona espaço à esquerda para mover as imagens para a direita */
}

#image1,
#image2 {
    height: 120%;
    width: auto;
    max-height: 31rem !important;
    transform: translateX(10px);
}