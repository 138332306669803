.section-footer {
    background-color: var(--dark-primary-color);
    color: var(--font-color-light);
    width: 100%;
    margin-top: 4rem;

}

.footer-container {
    max-width: 1140px;
    margin: 0 auto;
}

.footer-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.contact-info {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.contact-info-icon svg {
    margin-right: 4px;
    width: 26px;
    height: 26px;
    color: var(--primary-color);
}

.contact-info a {
    text-decoration: none;
}

.contact-info a:hover {
    color: var(--font-color-link);
    text-decoration: underline;
}

.contact-info a .contact-info-text {
    color: var(--font-color-light);
}

.footer-info-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 50%;
}

.footer-info-content-wrapper {
    width: 100%;
    font-size: 15px;
}

.footer-info-content-wrapper p {
    margin: 0;
}

.footer-info-title {
    font-weight: 700;
    margin: 0;
}

.footer-divider {
    border: none;
    border-top: 1px solid #3b3b4d;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 49%);
}

.footer-links {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    margin-bottom: 20px;
}

.footer-links a {
    font-size: 12px;
    text-decoration: none;
    color: var(--font-color-light);
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-pix {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 6px;
    position: relative;
    top: -13px;
}

.footer-pix img {
    width: 40px;
    /* Tamanho do ícone PIX */
    height: auto;
    margin-right: 10px;
    margin-top: -3px;
    /* Ajusta o ícone individualmente */
}

.footer-pix p {
    font-size: 14px;
    color: var(--font-color-light);
    margin: 0;
}

.dark-section {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 50%;
    background-color: var(--dark-primary-color);
    /* Substitui a cor comentada */
}