/* Estilização da seção principal dos planos */
.container-plano {
    display: flex;
    flex-direction: column;
    /* Alinha o conteúdo na vertical */
    justify-content: flex-start;
    /* Alinha o conteúdo no topo */
    align-items: center;
    padding: 2rem 1rem;
}

/* Cabeçalho dos planos */
.header-Plano {
    display: flex;
    justify-content: center;
    /* Alinha o título ao centro */
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    /* Espaçamento abaixo do header */
}

.header-Plano h2 {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 700;
    margin: -6px;
    margin-left: -42rem;
    cursor: pointer;
}

.header-Plano h2:hover {
    color: var(--dark-primary-color);
}

/* Grid responsivo com 4 colunas */
/* Layout da grid para os cards */
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 colunas em telas grandes */
    gap: 20px;
}

/* Card individual */
.plan-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--card-bg-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    /* Herda a cor do texto */
    width: 100%;
}

/* Efeito de hover no card */
.plan-card-wrapper:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Estilo do título dentro do card */
.plan-card-wrapper h3 {
    font-size: 24px;
    font-weight: 700;
    color: var(--font-color-dark);
    margin: 10px 0;
}

/* Descrição do plano */
.plan-card-wrapper p {
    font-size: 16px;
    color: var(--font-color-medium);
    margin: 10px 0;
}

/* Estilo do preço */
.plan-card-wrapper .price {
    font-size: 20px;
    font-weight: 700;
    color: var(--font-color-dark);
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 24px;
}

/* Responsividade para telas menores */
@media screen and (max-width: 1024px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
        /* 2 colunas em telas médias */
    }
}

@media screen and (max-width: 600px) {
    .grid {
        grid-template-columns: 1fr;
        /* 1 coluna em telas pequenas */
    }
}

/* Títulos dos cards */