.section-mission-vision-values {
				background-color: var(--dark-primary-color);
				 padding: 60px 20px; 
				text-align: center;
				color: var(--neutral-color-light);
				width:  100%;
                
			}

			.mission-vision-values-header h2 {
				font-size: 48px;
				color: var(--font-color-light);
				margin-bottom: 40px;
				font-weight: 600;
			}

			.mission-vision-values-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				gap: 20px;
				flex-wrap: wrap;
			}

			/* Estilo individual para missão, visão e valores */
			.mission,
			.vision,
			.values {
				flex: 1 1 300px;			
				min-width: 300px;			
				max-width: 300px;
				background-color: var(--dark-secondary-color);
				padding: 20px;
				margin: 10px;
				border-radius: 15px;
				box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
				transition: transform 0.3s ease;
			}

			/* Efeito hover */
			.mission:hover,
			.vision:hover,
			.values:hover {
				transform: scale(1.05);
			}

			/* Estilo dos títulos */
			.mission h3,
			.vision h3,
			.values h3 {
				font-size: 28px;
				color: var(--primary-color);
				margin-bottom: 20px;
			}

			/* Estilo do texto */
			.mission p,
			.vision p,
			.values p {
				font-size: 16px;
				color: var(--neutral-color-light);
				line-height: 1.8;
				margin-bottom: 10px;
			}

			/* Lista de valores */
			.values ul {
				list-style-type: none;
				padding: 0;
			}

			.values li {
				text-align: left;
				font-size: 16px;
				margin: 5px 0;
				color: var(--neutral-color-light);
			}

			.values li strong {
				color: var(--primary-color);
			}

			/* Estilo das imagens */
			.mission img,
			.vision img,
			.values img {
				max-width: 100%;
				/* A imagem não excederá a largura do contêiner */
				height: auto;
				/* Manter a proporção da imagem */
				margin-bottom: 15px;
				/* Espaço entre a imagem e o texto */
				border-radius: 10px;
				/* Arredondar as bordas da imagem, se desejado */
			}