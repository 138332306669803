:root {
    /* colors  */
    --primary-color: #187e36;
    --dark-primary-color: #0b0a1e;
    --light-primary-color: #eff2fb;
    --light-secondary-color: #e6dffe;
    --secondary-color: #1f28d8;
    --dark-secondary-color: #1a183e;

    --neutral-color-light: #ffffff;
    --neutral-color-dark: #000000;

    /* font  */
    --font-color-dark: #2b2c34;
    --font-color-medium: #6a6a92;
    --font-color-light: #ffffff;
    --font-color-link: #0056b3;
    --font-poppins: "Poppins", sans-serif;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-bold: 700;
    --font-size-small: 14px;
    --font-size-medium: 18px;
    --font-size-large: 32px;
    --font-size-xl: 48px;
    --font-size-h1: 32px;
    --font-size-h2: 24px;
    --font-size-h3: 20px;
    --font-size-h4: 18px;
    --font-size-h5: 16px;
    --font-size-h6: 14px;
}

body,
a,
p,
li,
ul,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-poppins);
}

body {
    margin: 0;
    padding: 0;


}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 1140px;
    justify-content: space-between;
    min-width: 200px;
    min-height: 200px;
    width: 100%;
    height: 100%;
}



.section-header {
    display: flex;
    justify-content: center;
    /* Centraliza o conteúdo principal */
    align-items: center;
    width: 100%;
    padding: 0 20px;
    /* Espaçamento lateral */
    max-width: 1140px;
    margin: 0 auto;
    /* Centraliza a seção na tela */
}

.header-container {
    display: flex;
    justify-content: space-between;
    /* Título à esquerda, botão à direita */
    align-items: center;
    width: 100%;
}

.header-container h2 {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}

.button-container {
    display: flex;
    align-items: center;
    /* Alinha o botão verticalmente com o título */
    justify-content: flex-end;
    /* Alinha o botão à direita */
    margin-top: 2rem;
  
}


.header-container h2 {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    /* Remove margens extras */
}




.header-container h2:hover {
    color: var(--dark-primary-color);
    /* Cor ao passar o mouse */
    cursor: pointer;
    /* Muda o cursor para indicar interatividade */
}



.header-container span {
    color: var(--font-color-dark);
    font-size: var(--font-size-medium);
    font-weight: 600;
}

.header-container:hover {
    cursor: pointer;
    color: var(--primary-color);
}

.header-container .header-sing-in {
    color: var(--primary-color);
}

.header-options {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;

    width: 350px;

    border: none;
}

.header-sing-up {
    background-color: var(--primary-color);
    color: var(--neutral-color-light);
    padding: 10px 12px;
    border-radius: 10px;
    border: none;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0 0 10px var(--primary-color);
}

.header-sing-up:hover {
    background-color: rgb(36, 163, 36);
    box-shadow: 0 0 10px rgb(43, 216, 43);
    cursor: pointer;
}

.header-container img {
    width: 13rem;
    /* height: auto; */
    margin-top: -2rem;
}