.construction-container {
    text-align: center;
    padding: 20px;
    margin: 30px auto;
    background-color: #f9f9f9;
    border: 2px dashed #007bff;
    border-radius: 10px;
    max-width: 500px;
}

.construction-container img {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
}

.construction-container h2 {
    color: #007bff;
    margin-bottom: 10px;
}

.construction-container p {
    color: #555;
    font-size: 16px;
}