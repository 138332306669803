#section-statistics {
    padding: 30px 20px;
    width: 100%;
    position: relative;
}

.statistics-container {
    background-color: var(--primary-color);
    color: var(--font-color-light);
    border-radius: 25px;
    padding: 24px 13px;
    position: absolute;
    top: -39px;
    left: 49%;
    transform: translateX(-50%);
    z-index: 1;
}



.statistics-card-wrapper {
    display: flex;
    justify-content: center;
}

.statistics-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.statistics-card img {
    width: 9rem;
    height: auto;
    margin-right: -1rem !important;
    border-radius: 2rem;
    margin-left: 1rem !important;
}