:root {
    --font-poppins: "Poppins";
    --font-roboto: "Roboto";
    --font-open-sans: "Open Sans", sans-serif;
    --text-white: #fff8f8;
}

/* CSS para o Sidebar */
.container-sidebar {
    display: flex;
    flex-direction: column;
    background-color: #111B21;
    width: 15%;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
}

#log-megasender-sidebar {
    margin-top: 2rem;
    padding: 10px 5px;
}

.navgators {
    display: flex;
    flex-direction: column;
    height: 400px;
}

.link-formatted {
    font-family: var(--font-open-sans);
    text-decoration: none;
    color: var(--text-white);
    padding: 10px 10px 10px 20px;
}

/* Seção de Configuração de Perfil */
.configuracao-profile {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

/* Estilos para o dropdown de Configurações */
.user-dropdown {
    position: relative;
    width: 100%;
    color: white;
    cursor: pointer;
}

.user-dropdown-header {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    color: white;
    justify-content: space-between;
    width: 100%;
}

.user-dropdown-header i {
    margin-right: 10px;
}

/* Menu dropdown para abrir para cima */
.user-dropdown-menu {
    position: absolute;
    bottom: 100%;
    /* Posiciona o menu para cima, acima do cabeçalho */
    left: 0;
    width: 100%;
    /*  background-color: #34495e; */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    /* Sombras para baixo */
    display: none;
    /* Inicialmente oculto */
}

.user-dropdown.open .user-dropdown-menu {
    display: block;
    /* Torna o menu visível quando a classe "open" é adicionada */
}

/* Itens do menu dropdown */
.user-dropdown-item {
    padding: 8px 0;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
}

.user-dropdown-item:hover {
    background-color: #2c3e50;
}

.user-dropdown-item i {
    margin-right: 10px;
}

/* Ícone e nome do usuário no cabeçalho do dropdown */
.user-dropdown-header span {
    flex-grow: 1;
}

/* Opção de Logout */
.logout-option {
    padding: 10px;
    color: white;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
}

.logout-option:hover {
    background-color: #2c3e50;
}

/* Seta para abrir/fechar o dropdown */
.fa-chevron-down,
.fa-chevron-up {
    margin-left: auto;
}

/* Opções de Logout */
.logout-button {
    background: none;
    border: none;
    color: #ff4d4d;
    /* Cor vermelha */
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.logout-button i {
    margin-right: 15px;
}

