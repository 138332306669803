@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

/* Contêiner principal que organiza Sidebar e Construction */
:root{
    --font-padrao : "Inria Sans";
}

.containerTemplates {
    display: flex;
    height: 100vh;
    flex-direction: row;
    
}

.templates{
    width: 100%;
    display: flex;
    height: 100vh;
    flex-direction: column;

}

.containerTemplateSidebar{
    width: 100%;
    flex-direction: row;
    height: 10rem;
    display: flex;
    justify-content: space-between;
    padding: 0px 5px 0px 5px;
    gap: 6px; /* Espaçamento entre os cards */
    flex-wrap: wrap; /* Permite que os cards quebrem para a próxima linha em telas menores */
}


.container-main-templates-conteudo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
  overflow: hidden; /* Garante que nada ultrapasse o container */
  max-width: 1200px; /* Ajuste conforme necessário */
  margin-left: auto;
  margin-right: auto;
  /* height: 10rem; */
}



.container-button-templates{
    margin-top: 3rem;
    width: 100%;
    padding: 0px 15px 0px 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-family: var(--font-padrao);

}

/* .conteudo-card-template-carrosel{
  width: min-content;
} */
.swiper {
  width: 100%;

  
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 10px;


}
/* CONFIGURAÇÕES DE CARDS TOPS */

.gif-animation{
    width: 50%;

}

/* CARDS */

.card {
  overflow: hidden;
  position: relative;
  text-align: left;
  border-radius: 0.5rem;
  max-width: 290px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: #fff;
}

.dismiss {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  /* background-color: #fff; */
  color: black;
  border: transparent;
  font-weight: 300;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  transition: .3s ease;
}

/* .dismiss:hover {
  background-color: #ee0d0d;
  border: 2px solid #ee0d0d;
  color: #fff;
} */

.header {
  padding: 1.25rem 1rem 1rem 1rem;
}

.image {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #e2feee;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  animation: animate .6s linear alternate-reverse infinite;
  transition: .6s ease;
}

.image svg {
  color: #0afa2a;
  width: 2rem;
  height: 2rem;
}

.content {
  margin-top: 0.75rem;
  text-align: center;
}

.title-card-template{
  color: #066e29;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.message {
  margin-top: 0.5rem;
  color: #595b5f;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.actions {
  margin: 0.75rem 1rem;
}

.history {
  display: inline-flex;
  padding: 0.5rem 1rem;
  background-color: #1aa06d;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.track {
  display: inline-flex;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  color: #242525;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #D1D5DB;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

@keyframes animate {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.09);
  }
}

.config-card-template{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.config-card-template span:first-child{
  font-weight: bold;
}

.uiid-code{
  display: flex;
  font-size: 12px;

}
.tooltip-target svg{
  width: 20px;
}