/* Contêiner principal que organiza Sidebar e Construction */
.main-container {
    display: flex;
    height: 100vh;

    /* Altura total da tela */
}

/* Container do histórico */
.container-historico {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    max-width: 1200px;
    width: 100%;
    /* Garante que ocupe o espaço disponível */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centraliza o conteúdo */
    margin: 0 auto;
}

/* Título da seção */
.container-historico h2 {
    color: #007bff;
    margin: 20px 0;
    font-size: 24px;
    /* Tamanho maior para o título */
}




/* Adaptação responsiva para os filtros */
@media (max-width: 768px) {
    .filters-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .filter-container {
        width: 100%;
        margin-bottom: 10px;
        /* Adiciona um espaço entre os filtros em telas pequenas */
    }
}

/* Estilo para o container dos filtros */

.filter-container {
    display: flex;
    justify-content: center;
    /* Centraliza horizontalmente */
    align-items: center;
    /* Centraliza verticalmente (opcional) */
}

/* Input de data */
.filter-container input[type="date"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
}

/* Select da instância */
.filter-container select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
    /* Aumenta a largura do select */
    margin-top: 5px;
    /* Espaçamento entre o texto "Instância" e o select */
}

/* Label de texto (Instância:) */
.filter-container label {
    display: flex;
    flex-direction: column;
    /* Posiciona o texto acima do select */
    font-size: 16px;
    font-weight: bold;
    /* Deixa o texto em negrito, opcional */
}


/* Mensagens de status de carregamento */
.container-historico-content {
    margin-top: 20px;
}

.container-historico-content p {
    font-size: 18px;
    color: #333;
}

.container-historico-content p.loading {
    color: #007bff;
    font-weight: bold;
}

.container-historico-content p.no-instances {
    color: #ff4d4d;
}

/* Contêiner para os botões */

/* Contêiner para os botões */
.container-btn-file {
    display: flex;
    justify-content: center;
    /* Centraliza os botões */
    gap: 20px;
    /* Espaçamento entre os botões */
    margin-top: 20px;
    /* Espaçamento superior */
    align-items: center;
    /* Alinha os botões verticalmente */
    width: 100%;
    /* Garante que ocupe a largura total */
}

/* Estilo para os botões */
.container-btn-file .button-excel,
.container-btn-file .button-download {
    background-color: #007bff;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    height: 48px;
    /* Defina uma altura fixa para os botões */
}

/* Hover effect para os botões */
.container-btn-file .button-excel:hover,
.container-btn-file .button-download:hover {
    background-color: #0056b3;
}

/* Adaptação responsiva para garantir que os botões fiquem lado a lado também em telas pequenas */
@media (max-width: 768px) {
    .container-btn-file {
        flex-direction: row;
        gap: 10px;
    }

    .container-btn-file .button-excel,
    .container-btn-file .button-download {
        width: auto;
    }
}

/* Estilos para o container de gráficos */
.graficos-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
}

/* Estilo para cada card */
.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 48%;
    text-align: center;
    margin-bottom: 20px;
}

/* Estilo para o título dos cards */
.card h3 {
    margin-bottom: 15px;
    font-size: 1.2em;
    color: #007bff;
}

/* Adaptação responsiva para os cards em telas pequenas */
@media (max-width: 768px) {
    .card {
        width: 100%;
        /* Em telas pequenas, os cards ocupam toda a largura */
    }
}

.selection-and-chart-container {
    display: flex;
    justify-content: space-between;
    /* Espaço entre o card e o gráfico */
    width: 100%;
    margin-top: 20px;
    /* Espaçamento superior */
}

.selection-card {
    width: 60%;
    /* Ajuste a largura do card de seleção */
    margin-right: 20px;
    /* Espaço entre o card de seleção e o gráfico */
}

.chart-card {
    width: 60%;
    /* Ajuste a largura do gráfico */
}

/* Adaptação responsiva */
@media (max-width: 768px) {
    .selection-and-chart-container {
        flex-direction: column;
        /* Empilha os cards em telas pequenas */
    }

    .selection-card,
    .chart-card {
        width: 100%;
        /* Ambos ocupam 100% da largura */
        margin-right: 0;
        /* Remove a margem direita */
        margin-bottom: 20px;
        /* Espaçamento inferior */
    }
}


/* Ajuste para o gráfico de pizza */
.chart {
    width: 100%;
    height: 300px;
    /* Define a altura do gráfico */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Centraliza o conteúdo dentro do gráfico */
}

/* Estilo para o cabeçalho */
.metricas-container {
    text-align: center;
    /* Centraliza o texto */
    margin-bottom: 20px;
    /* Espaçamento abaixo do cabeçalho */
}

.title {
    font-size: 2em;
    /* Tamanho do título */
    font-weight: bold;
    /* Negrito */
}

.subtitle {
    font-size: 1.2em;
    /* Tamanho do texto menor */
    color: #555;
    /* Cor do texto menor */
}