.main-title {
    font-size: var(--font-size-xl);
    color: var(--font-color-dark);
    font-weight: var(--font-weight-bold);
    /* Título em negrito */
    margin: 17px;
    padding: 0px;
    line-height: 1;
}

.text {
    color: var(--font-color-medium);
    font-size: var(--font-size-medium);
}

.comparison-container {
    font-size: var(--font-size-small);
    color: var(--font-color-dark);
    margin-top: -6rem;
    height: 100%;
    margin-left: -27rem;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
}


.comparison-wrapper {
    background-color: #f6f3fd;
    padding: 17px;
    border-radius: 11px;
    margin-top: 6rem;
    margin-left: 32px;
}

.comparison-wrapper h4 {
    font-size: 0.9rem;
    /* Ajusta o tamanho da fonte */
    font-weight: var(--font-weight-bold);
    /* Negrito mais forte */
    margin: 0.2rem;
    padding: 0;
    margin-top: 5rem;
}


.comparison-card {
    background-color: var(--neutral-color-light);
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    justify-content: end;
    justify-items: end;
    margin-bottom: 20px;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.325);
}

.comparison-card-title {
    justify-self: start;
}

.comparison-card-icon {
    display: flex;
    justify-content: center;
    width: 100%;
}