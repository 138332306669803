/* Contêiner principal que organiza Sidebar e Construction */


/* Wrapper para o construction-container */
.construction-container-wrapper {
  display: flex;
  align-items: flex-start;
  /* Alinha ao topo */
  justify-content: center;
  /* Centraliza horizontalmente */
  flex-grow: 1;
  /* Ocupa todo o espaço restante */
  padding: 20px;
  /* Espaço ao redor */
}

/* Construction Container */
.construction-container {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 2px dashed #007bff;
  border-radius: 10px;
  max-width: 500px;
}

/* Ajuste da imagem */
.construction-container img {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

/* Título */
.construction-container h2 {
  color: #007bff;
  margin: 10px 0;
}

/* Texto */
.construction-container p {
  color: #555;
  font-size: 16px;
}