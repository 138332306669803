#section-services {
    padding: 60px 20px;
    text-align: center;
    width: 100%;
}

.services-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    grid-auto-flow: row;
    grid-template-areas:
        "a b c"
        "d d c";
}

.services-container h2 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 40px;
}

.services-wrapper h3,
p {
    margin: 0;
}

.services-wrapper .first-item {
    grid-area: a;
    background-color: var(--neutral-color-light);
}

.services-wrapper .secound-item {
    grid-area: b;
    background-color: var(--primary-color);
    color: var(--font-color-light);
}

.services-wrapper .secound-item h3 {
    color: var(--font-color-light);
}

.services-wrapper .secound-item button {
    background-color: var(--font-color-light);
    color: var(--primary-color);
}

.services-wrapper .third-item {
    grid-area: c;
    /*  background-color: var(--neutral-color-light); */
}

.services-wrapper .fourth-item {
    grid-area: d;
    height: fit-content;
    background-color: var(--primary-color);
    color: var(--font-color-light);
}

.services-wrapper .fourth-item h3 {
    color: var(--font-color-light);
}

.services-wrapper .fourth-item button {
    background-color: var(--font-color-light);
    color: var(--primary-color);
}

.services-card {
    padding: 50px 15px 30px 15px;
    border-radius: 10px;
}

.services-card h3 {
    font-size: 30px;
    color: var(--font-color-dark);
    font-weight: 600;
}

.services-card-footer {
    display: flex;
    justify-content: center;
    /* Centraliza a imagem */
    align-items: center;
    margin-top: 15px;
    /* Adiciona um espaço acima da imagem */
}

.services-card-footer img {
    width: 100%;
    /* Faz a imagem ocupar a largura total do cartão */
    max-width: 200px;
    /* Aumenta a largura máxima da imagem */
    height: auto;
    /* Mantém a proporção da imagem */
    border-radius: 10px;
    /* Bordas arredondadas para a imagem */
    object-fit: contain;
    /* Cobre o espaço sem distorcer */
}

.third-item .services-card-footer {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.services-card-footer button {
    font-size: 16px;
}