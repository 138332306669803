.section-faq {
    background-color: var(--dark-primary-color);
    padding-top: 80px;
    width: 100%;
}

.faq-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.faq-wrapper {
    width: 100%;
    height: 450px;
    overflow-y: auto;
    scrollbar-width: none;
}

.faq-wrapper h2 {
    margin-bottom: 25px;
    font-size: 48px;
    font-weight: 600;
    color: var(--font-color-light);
}

.faq-accordion {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-template-rows: auto;
    gap: 10px;
}

.faq-accordion-item {
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.faq-accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    background-color: var(--light-primary-color);

    font-size: 18px;
    font-weight: 600;
    color: var(--font-color-dark);

    transition: background-color 0.3s ease, color 0.5s ease, border-bottom 0.3s ease;
}

.faq-accordion-header.active {
    background-color: var(--primary-color);
    color: var(--font-color-light);
    border-radius: 10px 10px 0 0;
    border-bottom: 2px solid #ccc;
}

.faq-accordion-content {
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease, background-color 0.3s ease;
    padding: 0 20px;
    /* Removi o padding inferior para dar mais flexibilidade */
    background-color: transparent;
}

.faq-accordion-header.active+.faq-accordion-content {
    display: block;
    background-color: var(--neutral-color-light);
    border-radius: 0 0 10px 10px;
    max-height: 500px;
    /* Defina um valor alto, ou até mais, se necessário */
    padding: 12px 20px;
}

.faq-accordion-content p {
    font-size: 0.8rem;
}

.faq-icon {
    rotate: -90deg;
    width: 38px;
    height: 38px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--neutral-color-light);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.faq-icon svg {
    width: 18px;
}

.faq-accordion-header.active .faq-icon {
    background-color: var(--light-primary-color);
    color: var(--primary-color);
}

.faq-accordion-header.active .faq-icon svg {
    color: var(--primary-color);
}
.faq-icon img {
    transition: transform 0.3s ease; /* Transição suave */
  }
  
  .faq-accordion-header.active .faq-icon img {
    transform: rotate(180deg); /* Gira a seta quando o item está ativo */
  }
  