@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

:root {
  --font-inriaSans: "Inria Sans";
  --orange-color: #e85d04;
  --green-color: #008000;
  --red-color: #da2c38;
  --yellow-color: #f2bb05;
}

.dashboard-container {
  width: 82%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.welcome-banner {
  width: 100%;
  height: 150px;
  background-color: white;
  border: 2px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.square-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  /* Espaço entre os quadrados */
  width: 100%;
}

.square {
  flex: 1;
  /* Distribui igualmente o tamanho entre todos os quadrados */
  height: 100px;
  background-color: white;
  border: 2px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}


.square i {
  font-size: 16pt;
}

.fa-fire {
  color: var(--orange-color);
}

.square span {
  font-family: var(--font-inriaSans);
}

.title-square {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 10px;

}

.body-square {
  text-align: center;
  font-size: 18pt;
}

.ds {
  color: var(--red-color);
}

.cn {
  color: var(--green-color);
}

.fa-star {
  color: var(--yellow-color);
}

.table {
  font-family: var(--font-inriaSans);

}

.disconected {
  background-color: var(--red-color);
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px 3px 10px;
  color: #ffffff;
}

.conected {
  background-color: var(--green-color);
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px 3px 10px;
  color: #ffffff;
}

.continer-table {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  gap: 10px;
}

.container-search {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comands-painel {
  display: flex;
  justify-content: space-between;
}

.container-search i {
  font-size: 17pt;
}

.container-search {
  font-family: var(--font-inriaSans);
}

.dashboard-container-section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;


}

.aquecimento {
  background-color: var(--orange-color);
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px 3px 10px;
  color: #ffffff;
}

.pagination-container {
  margin: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
  gap: 9px;
  /* Espaço mais balanceado */
}

.pagination-btn {
  padding: 10px 20px;
  /* Melhor para cliques */
  border: 2px solid #ddd;
  border-radius: 25px;
  /* Botões mais arredondados */
  background-color: #f1f1f1;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  /* Tamanho de texto amigável */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Leve sombra */
}

.pagination-btn.active {
  background: linear-gradient(45deg, #00c853, #14b90e);
  /* Gradiente para destaque */
  color: white;
  border-color: #14b90e;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 200, 83, 0.4);
  /* Sombra de destaque */
}

.pagination-btn:hover:not(.active) {
  background-color: #e0f7fa;
  color: #14b90e;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

.pagination-btn:focus {
  outline: none;
  border-color: #14b90e;
  box-shadow: 0 0 5px #14b90e;
}

.pagination-btn.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .pagination-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .pagination-container {
    gap: 8px;
  }
}


@media (max-width: 600px) {
  .pagination-btn {
    padding: 6px 12px;
    /* Botões menores em telas pequenas */
    font-size: 12px;
    /* Reduzido para telas menores */
  }
}