/* Container Principal */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 */
#root {
    /* Colors */
    --primary-color: #187e36;
    --dark-primary-color: #0b0a1e;
    --light-primary-color: #eff2fb;
    --light-secondary-color: #e6dffe;
    --secondary-color: #1f28d8;
    --dark-secondary-color: #1a183e;

    --neutral-color-light: #ffffff;
    --neutral-color-dark: #000000;

    /* Font */
    --font-color-dark: #2b2c34;
    --font-color-medium: #6a6a92;
    --font-color-light: #ffffff;
    --font-color-link: #0056b3;
    --font-poppins: "Poppins", sans-serif;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-bold: 700;
    --font-size-small: 14px;
    --font-size-medium: 18px;
    --font-size-large: 32px;
    --font-size-xl: 48px;
    --font-size-h1: 32px;
    --font-size-h2: 24px;
    --font-size-h3: 20px;
    --font-size-h4: 18px;
    --font-size-h5: 16px;
    --font-size-h6: 14px;
}

body,
a,
p,
li,
ul,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-poppins);
}

body {
    /*   margin: 0;
    padding: 0; */

    /* background-color: var(--light-primary-color); */
}

.sectio-main {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    /* Alinha os itens à esquerda */
    max-width: 1140px;
    width: 100%;
    margin-left: 20px;
    /* Dá um pequeno espaço da borda esquerda */
    margin-top: 3rem;
}


.sectio-main .main-item {
    max-width: 100%;
    min-width: 489px;
    min-height: 600px;

    width: 100%;
    margin-bottom: 19px;
    margin-left: 35%;
}

.main-title {
    font-size: var(--font-size-xl);
    color: var(--font-color-dark);
    margin: 0;
    padding: 0;
    line-height: 1;
}

.text {
    color: var(--font-color-medium);
    font-size: var(--font-size-medium);
}



/* Botão Padrão */
.button-pattern {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--font-color-light);
    padding: 15px;
    min-width: 160px;
    height: fit-content;
    border-radius: 10px;
    box-shadow: 0 0 49px 0 rgba(119, 80, 246, 28%);
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}

.button-pattern:hover {
    background-color: rgb(36, 163, 36);
    box-shadow: 0 0 10px rgb(43, 216, 43);
    cursor: pointer;
}

/* Formulário de Login */
.login-form-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 15px;
    width: 85%;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Inputs do Formulário */
.form-input-container {
    display: flex;
    align-items: center;
    max-height: 50px;
    margin-top: 1rem;
}

.form-input {
    width: 100%;
    border-radius: 8px;
    background-color: var(--neutral-color-light);
    margin-left: 5px;
    font-size: var(--font-size-small);
    color: var(--font-color-medium);
    padding: 0.56rem 0.75rem;
    line-height: 14px;
    font-weight: 300;
    border: 1px solid #d5dcec;


}

.input-icon {
    min-width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-icon svg {
    color: var(--neutral-color-light);
}

/* Opções do Login */
.login-features {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-size: var(--font-size-medium);
    font-weight: 600;
    color: var(--font-color-dark);
}

.forgot-password {
    color: var(--primary-color);
    text-decoration: none;
}

.forgot-password:hover {
    color: var(--font-color-link);
    text-decoration: underline;
}

.checkbox {
    margin-right: 8px;
}

/* Botões do Formulário */
.form-button-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-family: Open Sans, sans-serif;
    margin-top: 5px;
}

.form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px;
    padding: 15px;
    background-color: var(--primary-color);
    border-radius: 10px;
    box-shadow: 0 0 49px 0 rgba(119, 80, 246, 28%);
    color: var(--font-color-light);
    font-size: var(--font-size-small);
}

.form-button-icon {
    display: flex;
    align-items: center;
}



/* Responsividade */
@media (max-width: 768px) {
    .section-main .main-item {
        width: 90%;
        margin-left: 0;
    }

    .main-title {
        font-size: var(--font-size-h2);
    }

    .text {
        font-size: var(--font-size-small);
    }

    .button-pattern,
    .form-button {
        padding: 10px;
        font-size: var(--font-size-small);
    }
}

#section-services {
    padding: 60px 20px;
    text-align: center;
    width: 100%;
}

.services-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    grid-auto-flow: row;
    grid-template-areas:
        "a b c"
        "d d c";
}

