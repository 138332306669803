.sectionRegist {
    padding: 30px 20px;
    width: 100%;
    position: relative;
}

.offer-container {
    background-color: var(--primary-color);
    padding: 54px 27px;
    z-index: 1;
    border-radius: 25px;
    position: absolute;
    top: 33px;
    left: 49%;
    transform: translateX(-50%);
    z-index: 1;
    color: var(--font-color-light);
}

.offer-container h2 {
    font-size: 30px;
    font-weight: 600;
    margin-left: 1rem;

}

.offer-container p {
    font-size: 18px;
}

.offer-container .button-pattern {
    background-color: var(--light-primary-color);
    color: var(--primary-color);
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 1rem;
}

.offer-container .button-pattern:hover {
    background-color: #115703;
    color: var(--light-primary-color);
}

.background-container {
    position: absolute;
    top: -4px;
    left: 0px;
    width: 100%;
    height: 127%;
    display: flex;
    flex-direction: column;
}

.transparent-section {
    position: relative;
    z-index: -1;
    width: 100%;
    /* Ocupa toda a largura */
    height: 50%;
    /* Ocupa metade da altura */
    background-color: transparent;
}

.dark-section {
    position: relative;
    z-index: -1;
    width: 100%;
    /* Ocupa toda a largura */
    height: 50%;
    /* Ocupa metade da altura */
    background-color: var(--dark-primary-color);
}
.register-button{
    width: 100%;
    display: flex;
    height: auto;
    justify-content: end;
    margin-top: 1rem ;
}