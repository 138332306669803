#section-blogs {
    text-align: center;
    margin-top: 2rem;
    width: 100%;
}

.blogs-container {
    padding: 0 20px;
    max-width: 1140px;
}

.blogs-container h2 {
    font-size: 48px;
    font-weight: 600;
    color: var(--font-color-dark);
    margin: 40px 0;
}

.blog-card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Usar repeat para flexibilidade */
    gap: 20px;
    width: 100%;
}

.blog-card {
    text-decoration: none;
    color: inherit;
    /* Garante que a cor do texto herda o estilo padrão */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--card-bg-color);
    padding: 15px;
    border-radius: var(--card-border-radius);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 100%;
}

/* Mantém o efeito de hover */
.blog-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Estilo das imagens dentro dos cards */
.blog-card img {
    width: 79%;
    height: 150px;
    object-fit: cover;
    margin-left: 2rem;
    border-radius: var(--card-border-radius);
}

/* Remove sublinhado dos links */
.blog-card a {
    text-decoration: none;
    color: inherit;
    /* Herda a cor do texto sem alteração */
}

/* Conteúdo do card */
.blog-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0;
}

.blog-card-text-wrapper {
    overflow-y: hidden;
}

/* Estilo do parágrafo */
.blog-card-content p {
    font-size: 18px;
    color: var(--font-color-medium);
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: none;
}

/* Estilo do título */
.blog-card-content h3 {
    max-width: 100%;
    font-size: 20px;
    font-weight: 700;
    color: var(--font-color-dark);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Botão nos cards (caso queira usar) */
.blog-card button {
    padding: 10px 20px;
}