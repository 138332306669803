:root {
    --advantages-carousel-card-width: 380px;
    --advantages-carousel-items-to-show: 3;
    --carousel-total-items: 6;


}

.section-advantages {
    background-color: var(--neutral-color-light);
    width: 100%;


}

.advantages-carousel-container {
    display: flex;
    align-items: center;
    position: relative;


}

.advantages-carousel {
    width: calc(var(--advantages-carousel-card-width) * var(--advantages-carousel-items-to-show));
    overflow: hidden;
    position: relative;

}

.advantages-carousel h2 {
    font-size: 48px;
    font-weight: 600;
    color: var(--font-color-dark);
    text-align: center;
    margin: 2rem 0px 1rem 0px;
    width: 100%;

}

.advantages-carousel-inner {
    display: flex;
    gap: 24px;
    transition: transform 0.5s ease-in-out;
    width: calc(var(--advantages-carousel-card-width) * (var(--carousel-total-items + 2)));
    /* Inclui 2 para compensar o looping */

}

.advantages-carousel-item {
    min-width: var(--advantages-carousel-card-width);
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.advantages-carousel-item h2 {
    font-size: 24px;
    color: var(--font-color-dark);
}

.advantages-carousel-item p {
    font-size: 15px;
    color: var(--font-color-dark);
}

.advantages-carousel-card {
    height: 350px;
    width: 100%;
    background-color: var(--light-primary-color);
    padding: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 182;
}

.advantages-carousel-card:hover {
    background-color: var(--primary-color);
    transition: background-color 1s linear;
}

.advantages-carousel-card:hover h2,
.advantages-carousel-card:hover p {
    color: var(--font-color-light);
    transition: color 1s linear;
}

.advantages-carousel-button-wrapper {
    display: flex;
    gap: 10px;
}

.advantages-carousel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 0;
    background-color: var(--primary-color);
    color: var(--neutral-color-light);
}

.advantages-carousel-button svg {
    width: 24px;
    height: 24px;
}

.advantages-carousel-prev {
    position: absolute;
    left: -70px;
}

.advantages-carousel-next {
    position: absolute;
    right: -70px;
    transform: rotate(180deg);
}